<template>
  <div class="popup_wrap" style="width:800px;">
    <!-- popup_wrap -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ this.$t('msg.CSBK100_P1.002') }}</h1>
      <div class="content_box">
        <!-- content_box -->
        <div id="pickupGrid" style="width:100%; height:600px;" />
      </div><!-- content_box -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import trans from '@/api/rest/trans/trans'
import cp from '@/api/rest/cp/cp'
import { rootComputed } from '@/store/helpers'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'plcEnm',
    dataType: 'text'
  },
  {
    fieldName: 'cyCd',
    dataType: 'text'
  },
  {
    fieldName: 'cyNm',
    dataType: 'text'
  },
  {
    fieldName: 'statusNm',
    dataType: 'text'
  },
  {
    fieldName: 'picNm',
    dataType: 'text'
  },
  {
    fieldName: 'picTel',
    dataType: 'text'
  },
  {
    fieldName: 'rfHmpgUrl',
    dataType: 'text'
  },
  {
    fieldName: 'rstChk'
  }
]

export const columns = [
  {
    name: 'plcEnm',
    fieldName: 'plcEnm',
    type: 'text',
    width: '150',
    header: {
        text: '위치'
    }
  },
  {
    name: 'cyCd',
    fieldName: 'cyCd',
    type: 'text',
    width: '100',
    header: {
        text: 'Code'
    }
  },
  {
    name: 'cyNm',
    fieldName: 'cyNm',
    type: 'text',
    width: '230',
    header: {
        text: 'Pick Up 장소'
    }
  },
  {
    name: 'statusNm',
    fieldName: 'statusNm',
    type: 'text',
    width: '130',
    header: {
      text: '상태'
    }
  },
  {
    name: 'picNm',
    fieldName: 'picNm',
    type: 'text',
    visible: false
  },
  {
    name: 'picTel',
    fieldName: 'picTel',
    type: 'text',
    visible: false
  },
  {
    name: 'rfHmpgUrl',
    fieldName: 'rfHmpgUrl',
    type: 'text',
    visible: false
  },
  {
    name: 'pFlag',
    fieldName: 'pFlag',
    type: 'text',
    visible: false
  },
  {
    name: 'rstChk',
    fieldName: 'rstChk',
    type: 'text',
    width: '80',
    header: {
        text: ' '
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let str = ''
        const value = cell.value
        if (value === '0' && vmAppPickup.auth.userCtrCd !== 'JP') {
          str = `<a class="button blue sm" onClick="vmAppPickup.selectedRow(${cell.index.dataRow})">${app.$t('msg.CSBK100.394')}</a>` // 선택
        } else {
          str = `<a class="button gray sm disabled">${app.$t('msg.CSBK100.395')}</a>` // 불가
        }

        return str
      }
    }
  }
]

export default {
  name: 'PickUpPlacePop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          selectRow: '0',
          cntrSize: '',
          cntrType: '',
          polPortCd: '',
          pickupDate: '',
          cyCd: '',
          mtyPkupUsagYn: '',
          iotCntrYn: ''
        }
      }
    }
  },
  computed: {
    ...rootComputed
  },
  data () {
    return {
      items: [],
      isPaging1: true,
      currentPage: 0,
      totalPage: 0
    }
  },
  mounted () {
    window.vmAppPickup = this
    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('pickupGrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)
    this.setMessageProperty()
    gridView.displayOptions.fitStyle = 'fill'
    gridView.displayOptions.rowHeight = 30
    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //리얼그리드 팝업 HEIGHT 조절
    $('#pickupGrid').height('450px')

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //푸터 없애기
    gridView.setFooter({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })

    const $vm = this
    //API : Pick Up 장소 리스트 가져오기
    $vm.getPickUpPlace()
    //Grid 메소드
    //$vm.gridEvents()
  },
  methods: {
    selectedRow: function (index) {
      const item = this.items[index]

      const obj = {
        plcEnm: item.plcEnm,
        cyCd: item.cyCd,
        cyNm: item.cyNm,
        picNm: item.picNm,
        picTel: item.picTel,
        rfHmpgUrl: item.rfHmpgUrl || '',
        selectIdx: this.parentInfo.selectRow
      }

      this.$emit('selectFunc', obj)
      this.$emit('close')
    },
    setPickUpInfo: function (clickData) {
      //this.$emit('selectFunc', clickData)
      console.log(clickData)
    },
    async getPickUpPlace () {
      this.items = []
      let form = this.parentInfo

      await trans.getPickUpList(form).then(async (data) => {
        const list = data.data.plcList

        let cpResult = null
        const params = {
          actionCd: 'Q201',
          cntrSize: this.parentInfo.cntrSize,
          cntrType: this.parentInfo.cntrType,
          pickupDate: this.parentInfo.pickupDate
        }
        await cp.getCallActionHandler(params).then(res => {
          cpResult = res.data
        })
        //셋팅할 데이터 to array
        list.forEach((item) => {
        //해당 조건에 있는 데이터는 픽업지 제한 확대로 선택 불가

          if (this.$ekmtcCommon.isNotEmpty(cpResult) && cpResult.result === 'Y') {
            const impossTrml = cpResult.impossTrml
            const possTrml = cpResult.possTrml

            if (this.$ekmtcCommon.isNotEmpty(impossTrml)) {
              if (impossTrml.indexOf(item.cyCd) > -1) {
                item.rstChk = ''
              }
            }

            if (this.$ekmtcCommon.isNotEmpty(possTrml)) {
              if (possTrml.indexOf(item.cyCd) > -1) {
                item.rstChk = '0'
              }
            }
          }

          if (item.pflag !== 'N') {
            //rstChk가 1인경우 컨테이너 재고부족
            item.statusNm = item.rstChk === '1' ? this.$t('msg.CSBK100_P1.001') : '' //컨테이너 재고 부족

            this.items.push(item)
          }
        })

        this.items.sort((a, b) => {
          let rstChkA = a.rstChk === '0' ? 1 : 2
          let rstChkB = b.rstChk === '0' ? 1 : 2

          if (rstChkA > rstChkB) return 1
          if (rstChkA < rstChkB) return -1

          return 0
        })

        //그리드 데이터 셋팅
        provider.setRows(this.items)
      })
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    getBtnStatus (item) {

    },
    gridEvents () {
      /*
      const TH = this
      gridView.onCellClicked = function (grid, clickData) {
        const current = gridView.getCurrent()
        const fieldName = current.fieldName
        const clickedField = provider.getValue(current.dataRow, current.fieldName)
        //선택 버튼 제어

        if (fieldName === 'rstChk') {
          if (clickedField !== '0') {
            //alert('선택불가')
          } else {
            const selectedData = provider.getValues(clickData.dataRow)
            const obj = {
              plcEnm: selectedData[0],
              cyCd: selectedData[1],
              cyNm: selectedData[2],
              picNm: selectedData[4],
              picTel: selectedData[5],
              rfHmpgUrl: selectedData[6],
              selectIdx: TH.parentInfo.selectRow
            }

            TH.$emit('selectFunc', obj)
            TH.$emit('close')
          }
        }
      }
      */
    },
    //다국어 적용
    setMessageProperty () {
      const header1 = gridView.getColumnProperty('plcEnm', 'header')
      const header2 = gridView.getColumnProperty('cyCd', 'header')
      const header3 = gridView.getColumnProperty('cyNm', 'header')
      const header4 = gridView.getColumnProperty('statusNm', 'header')
      const header5 = gridView.getColumnProperty('rstChk', 'header')

      header1.text = this.$t('msg.CSBK100.080') //위치
      header2.text = this.$t('msg.CSBK100.134') //CODE
      header3.text = this.$t('msg.CSBK100.106') //Pick Up 장소
      header4.text = this.$t('msg.CSBK100.135') //상태
      header5.text = this.$t('msg.CSBK100.136') //SELECT

      gridView.setColumnProperty('plcEnm', 'header', header1)
      gridView.setColumnProperty('cyCd', 'header', header2)
      gridView.setColumnProperty('cyNm', 'header', header3)
      gridView.setColumnProperty('statusNm', 'header', header4)
      gridView.setColumnProperty('rstChk', 'header', header5)
    }
  }
}
</script>

<style scoped>
</style>
